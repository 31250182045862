import React from 'react'
import './header.scss'
import loadable from '@loadable/component'
import {scrollView} from '../../util/scrollView'
import Marked from 'react-markdown'
const Fade = loadable(() => import('../transition/Fade'))

const Header = ({ data }) => {
  return (
    <div className="header-section d-lg-flex align-items-lg-center" id="common-pl">
      <div className="title-container">
        <div>
          <p className="sub-title">Our</p>
          <Fade timeout={500} up>
            <h1>Culture</h1> 
          </Fade>
          <Fade timeout={1000} up>
            <p className="title-dec"><Marked parserOptions={{ commonmark: true}} source={data.frontmatter.bannerDescription} /></p>
          </Fade>
        </div>
      </div>
      <div className="background-text">
        <img loading="lazy" src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614325725/CULTURE_w81bpw.png" alt="culture" className="text-img img-fluid" />
      </div>
      <div className="d-flex align-items-end scroll-text">
        <p onClick={() => scrollView('story')} aria-hidden="true">
          SCROLL <br />
          FOR <br />
          MORE
        </p>
      </div>
      {data.frontmatter.ceo.map(({ image, alt, fullName }, index) => (
        <div
          className="img-main-container d-lg-flex align-items-lg-end"
          key={index}
        >
          <div className="img-dec d-none d-lg-block">
            <p>{fullName}</p>
            <p>CEO</p>
          </div>
          <div className="img-container ">
            <Fade timeout={1000} up>
              <img loading="lazy" src={image} alt={alt} className="culture-img" /> 
            </Fade>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Header
